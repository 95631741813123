





































































































































































































































































import SfLoader from '/storefrontUI/components/components/atoms/SfLoader/SfLoader.vue';
import SfNotification from '/storefrontUI/components/components/molecules/SfNotification/SfNotification.vue';
import SfSidebar from '/storefrontUI/components/components/organisms/SfSidebar/SfSidebar.vue';
import SfHeading from '/storefrontUI/components/components/atoms/SfHeading/SfHeading.vue';
import SfButton from '/storefrontUI/components/components/atoms/SfButton/SfButton.vue';
import SfProperty from '/storefrontUI/components/components/atoms/SfProperty/SfProperty.vue';
import SfPrice from '/storefrontUI/components/components/atoms/SfPrice/SfPrice.vue';
import SfCollectedProduct from '/storefrontUI/components/components/organisms/SfCollectedProduct/SfCollectedProduct.vue';
import SfQuantitySelector from '/storefrontUI/components/components/atoms/SfQuantitySelector/SfQuantitySelector.vue';
import SfBadge from '/storefrontUI/components/components/atoms/SfBadge/SfBadge.vue';
import SfImage from '/storefrontUI/components/components/atoms/SfImage/SfImage.vue';
import {
  defineComponent,
  useRouter,
  useContext, ref,
} from '@nuxtjs/composition-api';
import {
  useUiState,
} from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import CouponCode from '~/components/CouponCode.vue';
import { useCartView } from '~/modules/checkout/composables/useCartView';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SfLoader,
    SfNotification,
    SfSidebar,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfCollectedProduct,
    SfQuantitySelector,
    SfBadge,
    CouponCode,
    SvgImage,
    SfImage,
  },
  setup() {
    const cartView = useCartView();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const router = useRouter();
    const { app } = useContext();

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    return {
      ...cartView,
      isCartSidebarOpen,
      toggleCartSidebar,
      goToCart,
    };
  },
});
